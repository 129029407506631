"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numberToBytes32 = exports.joinPublicKey = exports.splitPublicKey = exports.isPublicKeyValid = exports.isHexStringPublicKeyPart = exports.isHexStringPublicKey = exports.isZeroHexString = exports.isHexString = void 0;
const ethers_1 = require("ethers");
const utils_1 = require("ethers/lib/utils");
const hex_types_1 = require("../model/hex.types");
const assert_1 = require("./assert");
const { hexStripZeros } = ethers_1.utils;
function isHexString(hexString) {
    return (0, utils_1.isHexString)(hexString);
}
exports.isHexString = isHexString;
function isZeroHexString(hexString) {
    return ethers_1.BigNumber.from(hexString).eq(ethers_1.BigNumber.from(0));
}
exports.isZeroHexString = isZeroHexString;
function isHexStringPublicKey(hexString) {
    return isHexString(hexString) && hexString.length === hex_types_1.PUBLIC_KEY_LENGTH;
}
exports.isHexStringPublicKey = isHexStringPublicKey;
function isHexStringPublicKeyPart(hexString) {
    return isHexString(hexString) && hexString.length === hex_types_1.PUBLIC_KEY_PART_LENGTH;
}
exports.isHexStringPublicKeyPart = isHexStringPublicKeyPart;
function isPublicKeyValid(publicKey) {
    return isHexStringPublicKey(publicKey) && hexStripZeros('0x' + publicKey.substring(4)) !== '0x';
}
exports.isPublicKeyValid = isPublicKeyValid;
function splitPublicKey(publicKey) {
    (0, assert_1.assert)(isPublicKeyValid(publicKey), 'Public key is not valid.');
    const publicKeyX = ('0x' + publicKey.substring(4, hex_types_1.PUBLIC_KEY_PART_LENGTH + 2));
    const publicKeyY = ('0x' +
        publicKey.substring(hex_types_1.PUBLIC_KEY_PART_LENGTH + 2, hex_types_1.PUBLIC_KEY_LENGTH + 2));
    return [publicKeyX, publicKeyY];
}
exports.splitPublicKey = splitPublicKey;
function joinPublicKey(publicKeyX, publicKeyY) {
    (0, assert_1.assert)(isHexStringPublicKeyPart(publicKeyX) && isHexStringPublicKeyPart(publicKeyY), 'One or both public key parts are not hex strings.');
    return ('0x04' + publicKeyX.substring(2) + publicKeyY.substring(2));
}
exports.joinPublicKey = joinPublicKey;
function numberToBytes32(number) {
    return (0, utils_1.hexZeroPad)(number.toHexString(), 32);
}
exports.numberToBytes32 = numberToBytes32;
