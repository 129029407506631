import { styled } from "@mui/system";

export const FlexDiv = styled("div")({
  display: "flex",
});

export const FlexColumnDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
});

export const MarginLeftAuto = styled("div")({
  marginLeft: "auto",
});
