"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Environments = void 0;
var Environments;
(function (Environments) {
    Environments[Environments["LOCALHOST"] = 0] = "LOCALHOST";
    Environments[Environments["GOERLI"] = 1] = "GOERLI";
    Environments[Environments["SEPOLIA"] = 2] = "SEPOLIA";
    Environments[Environments["OPTIMISM_GOERLI"] = 3] = "OPTIMISM_GOERLI";
    Environments[Environments["ARBITRUM_GOERLI"] = 4] = "ARBITRUM_GOERLI";
    Environments[Environments["ZKEVM_TESTNET"] = 5] = "ZKEVM_TESTNET";
})(Environments = exports.Environments || (exports.Environments = {}));
