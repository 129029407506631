"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDataHubEnvironmentConfig = exports.getDappRegistryEnvironmentConfig = exports.getEnsEnvironmentConfig = exports.DATA_HUB_ENVIRONMENT_CONFIGS = exports.DAPP_REGISTRY_ENVIRONMENT_CONFIGS = exports.ENS_ENVIRONMENT_CONFIGS = void 0;
const environments_enum_1 = require("../model/environments.enum");
exports.ENS_ENVIRONMENT_CONFIGS = {
    [environments_enum_1.Environments.LOCALHOST]: {
        rpcUrl: 'http://127.0.0.1:9545/',
        contractAddresses: {
            ensRegistry: process.env.DOCKER_ENS_REGISTRY_ADDRESS,
            fdsRegistrar: process.env.DOCKER_FDS_REGISTRAR_ADDRESS,
            publicResolver: process.env.DOCKER_PUBLIC_RESOLVER_ADDRESS,
            reverseResolver: process.env.DOCKER_REVERSE_RESOLVER_ADDRESS,
            nameResolver: process.env.DOCKER_NAME_RESOLVER_ADDRESS,
        },
        gasEstimation: 268359,
        performChecks: false,
    },
    [environments_enum_1.Environments.GOERLI]: {
        rpcUrl: 'https://xdai.dev.fairdatasociety.org/',
        contractAddresses: {
            ensRegistry: process.env.GOERLI_ENS_REGISTRY_ADDRESS,
            fdsRegistrar: process.env.GOERLI_FDS_REGISTRAR_ADDRESS,
            publicResolver: process.env.GOERLI_PUBLIC_RESOLVER_ADDRESS,
            reverseResolver: process.env.GOERLI_REVERSE_RESOLVER_ADDRESS,
            nameResolver: process.env.GOERLI_NAME_RESOLVER_ADDRESS,
        },
        gasEstimation: 268359,
        performChecks: false,
    },
    [environments_enum_1.Environments.SEPOLIA]: {
        rpcUrl: 'https://sepolia.dev.fairdatasociety.org/',
        contractAddresses: {
            ensRegistry: process.env.SEPOLIA_ENS_REGISTRY_ADDRESS,
            fdsRegistrar: process.env.SEPOLIA_FDS_REGISTRAR_ADDRESS,
            publicResolver: process.env.SEPOLIA_PUBLIC_RESOLVER_ADDRESS,
            reverseResolver: process.env.SEPOLIA_REVERSE_RESOLVER_ADDRESS,
            nameResolver: process.env.SEPOLIA_NAME_RESOLVER_ADDRESS,
        },
        gasEstimation: 268359,
        performChecks: false,
    },
    [environments_enum_1.Environments.OPTIMISM_GOERLI]: {
        rpcUrl: 'https://optimism-goerli.publicnode.com',
        contractAddresses: {
            ensRegistry: process.env.OPTIMISM_GOERLI_ENS_REGISTRY_ADDRESS,
            fdsRegistrar: process.env.OPTIMISM_GOERLI_FDS_REGISTRAR_ADDRESS,
            publicResolver: process.env.OPTIMISM_GOERLI_PUBLIC_RESOLVER_ADDRESS,
            reverseResolver: process.env.OPTIMISM_GOERLI_REVERSE_RESOLVER_ADDRESS,
            nameResolver: process.env.OPTIMISM_GOERLI_NAME_RESOLVER_ADDRESS,
        },
        gasEstimation: 268359,
        performChecks: false,
    },
    [environments_enum_1.Environments.ARBITRUM_GOERLI]: {
        rpcUrl: 'https://arbitrum-goerli.rpc.thirdweb.com',
        contractAddresses: {
            ensRegistry: process.env.ARBITRUM_GOERLI_ENS_REGISTRY_ADDRESS,
            fdsRegistrar: process.env.ARBITRUM_GOERLI_FDS_REGISTRAR_ADDRESS,
            publicResolver: process.env.ARBITRUM_GOERLI_PUBLIC_RESOLVER_ADDRESS,
            reverseResolver: process.env.ARBITRUM_GOERLI_REVERSE_RESOLVER_ADDRESS,
            nameResolver: process.env.ARBITRUM_GOERLI_NAME_RESOLVER_ADDRESS,
        },
        gasEstimation: 268359,
        performChecks: false,
    },
    [environments_enum_1.Environments.ZKEVM_TESTNET]: {
        rpcUrl: 'https://rpc.public.zkevm-test.net',
        contractAddresses: {
            ensRegistry: process.env.ZKEVM_TESTNET_ENS_REGISTRY_ADDRESS,
            fdsRegistrar: process.env.ZKEVM_TESTNET_FDS_REGISTRAR_ADDRESS,
            publicResolver: process.env.ZKEVM_TESTNET_PUBLIC_RESOLVER_ADDRESS,
            reverseResolver: process.env.ZKEVM_TESTNET_REVERSE_RESOLVER_ADDRESS,
            nameResolver: process.env.ZKEVM_TESTNET_NAME_RESOLVER_ADDRESS,
        },
        gasEstimation: 268359,
        performChecks: false,
    },
};
exports.DAPP_REGISTRY_ENVIRONMENT_CONFIGS = {
    [environments_enum_1.Environments.LOCALHOST]: {
        rpcUrl: 'http://127.0.0.1:9545/',
        dappRegistryAddress: process.env.DOCKER_DAPP_REGISTRY_ADDRESS,
        ratingsAddress: process.env.DOCKER_RATINGS_ADDRESS,
    },
    [environments_enum_1.Environments.GOERLI]: {
        rpcUrl: 'https://xdai.dev.fairdatasociety.org/',
        dappRegistryAddress: process.env.GOERLI_DAPP_REGISTRY_ADDRESS,
        ratingsAddress: process.env.GOERLI_RATINGS_ADDRESS,
    },
    [environments_enum_1.Environments.SEPOLIA]: {
        rpcUrl: 'https://sepolia.dev.fairdatasociety.org/',
        dappRegistryAddress: process.env.SEPOLIA_DAPP_REGISTRY_ADDRESS,
        ratingsAddress: process.env.SEPOLIA_RATINGS_ADDRESS,
    },
    [environments_enum_1.Environments.OPTIMISM_GOERLI]: {
        rpcUrl: 'https://optimism-goerli.publicnode.com',
        dappRegistryAddress: process.env.OPTIMISM_GOERLI_DAPP_REGISTRY_ADDRESS,
        ratingsAddress: process.env.OPTIMISM_GOERLI_RATINGS_ADDRESS,
    },
    [environments_enum_1.Environments.ARBITRUM_GOERLI]: {
        rpcUrl: 'https://arbitrum-goerli.rpc.thirdweb.com',
        dappRegistryAddress: process.env.ARBITRUM_GOERLI_DAPP_REGISTRY_ADDRESS,
        ratingsAddress: process.env.ARBITRUM_GOERLI_RATINGS_ADDRESS,
    },
    [environments_enum_1.Environments.ZKEVM_TESTNET]: {
        rpcUrl: 'https://rpc.public.zkevm-test.net',
        dappRegistryAddress: process.env.ZKEVM_TESTNET_DAPP_REGISTRY_ADDRESS,
        ratingsAddress: process.env.ZKEVM_TESTNET_RATINGS_ADDRESS,
    },
};
exports.DATA_HUB_ENVIRONMENT_CONFIGS = {
    [environments_enum_1.Environments.LOCALHOST]: {
        rpcUrl: 'http://127.0.0.1:9545/',
        dataHubAddress: process.env.DOCKER_DATAHUB_ADDRESS,
    },
    [environments_enum_1.Environments.GOERLI]: {
        rpcUrl: 'https://xdai.dev.fairdatasociety.org/',
        dataHubAddress: process.env.GOERLI_DATAHUB_ADDRESS,
    },
    [environments_enum_1.Environments.SEPOLIA]: {
        rpcUrl: 'https://sepolia.dev.fairdatasociety.org/',
        dataHubAddress: process.env.SEPOLIA_DATAHUB_ADDRESS,
    },
    [environments_enum_1.Environments.OPTIMISM_GOERLI]: {
        rpcUrl: 'https://optimism-goerli.publicnode.com',
        dataHubAddress: process.env.OPTIMISM_GOERLI_DATAHUB_ADDRESS,
    },
    [environments_enum_1.Environments.ARBITRUM_GOERLI]: {
        rpcUrl: 'https://arbitrum-goerli.rpc.thirdweb.com',
        dataHubAddress: process.env.ARBITRUM_GOERLI_DATAHUB_ADDRESS,
    },
    [environments_enum_1.Environments.ZKEVM_TESTNET]: {
        rpcUrl: 'https://rpc.public.zkevm-test.net',
        dataHubAddress: process.env.ZKEVM_TESTNET_DATAHUB_ADDRESS,
    },
};
function getEnsEnvironmentConfig(environment) {
    return exports.ENS_ENVIRONMENT_CONFIGS[environment];
}
exports.getEnsEnvironmentConfig = getEnsEnvironmentConfig;
function getDappRegistryEnvironmentConfig(environment) {
    return exports.DAPP_REGISTRY_ENVIRONMENT_CONFIGS[environment];
}
exports.getDappRegistryEnvironmentConfig = getDappRegistryEnvironmentConfig;
function getDataHubEnvironmentConfig(environment) {
    return exports.DATA_HUB_ENVIRONMENT_CONFIGS[environment];
}
exports.getDataHubEnvironmentConfig = getDataHubEnvironmentConfig;
